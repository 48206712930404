export const releaseInfo =
 {
 	branch: "beta",
 	lastCommit: "03/27/2025 20:28:45",
 	lastTag: "v5.2.0",
 	buildType: "local",
 	company: "Pinnacle Power Services",
 	user: "dmorr",
 	compileTime: "03/27/2025 20:40:59"
 }
